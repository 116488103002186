import React from "react";
import { Row, Col } from "reactstrap";
import facebook from "../assets/images/facebook2.png";
import twitter from "../assets/images/twitter.png";
import youtube from "../assets/images/youtube_play.png";
const Footer = () => {
  return (
    <Row className="footer_main my-5 mx-0">
      <Col sm="1"></Col>
      <Col sm="2" md="3" lg="2" className="d-flex justify-content-between mt-3">
        <p className="paragraph-18">Find Us: </p>
        <a
          href="https://www.youtube.com/channel/UCyB3Bf9z_qibYFgTh59IIjQ"
          className="w-inline-block mx-2"
        >
          <img src={youtube} loading="lazy" alt="" className="image-17" />
        </a>
        <a
          href="https://www.facebook.com/FullCircleLearning/"
          className="w-inline-block mx-2"
        >
          <img src={facebook} loading="lazy" alt="" className="image-18" />
        </a>
        <a
          href="https://twitter.com/fullcirclelearn"
          className="w-inline-block mx-2"
        >
          <img src={twitter} loading="lazy" alt="" className="image-19" />
        </a>
      </Col>
      <Col sm="4" md="4"></Col>
      <Col sm="4" md="4" className="d-flex flex-column px-3">
        <div className="link_hov">
          To receive news updates from Full-Circle Learning,
          <br /> please send your email address to
          <a href="mailto:info@fullcirclelearning.org">
            {" "}
            info@fullcirclelearning.org
          </a>
        </div>
        <div className="">©2021 Full-Circle Learning. All rights reserved</div>
      </Col>
      <Col sm="1"></Col>
    </Row>
  );
};

export default Footer;
